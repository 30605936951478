import React from 'react';

export default ({ title, bg, image, children }) => (
  <div className="mt3 row">
    <div className="col-xs-0 col-sm-1 col-md-2 col-lg-2"></div>
    <div className="col-xs-12 col-sm-10 col-md-8 col-lg-8">
      <div className={`pa4 ${bg}`}>
        <div className="tc">
          <img className="w-30" src={image} />
          <h3>{ title }</h3>
        </div>
        <div className="flex justify-center">
          { children }
        </div>
      </div>
    </div>
  </div>
)